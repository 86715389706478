// import queryString from "query-string";
import { publicBucket, cookieOpions, osritEnv, URLS } from "../../constants";

// const qp = queryString.parse(window.location.search);

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const defaultState = {
  authBusy: false,
  // isMainApp: !qp.isIframe,
  isMainApp: !isInIframe(),
  isQaOnly: osritEnv !== "LIVE",
  baseUrl: URLS.CORE_API,
  publicBucket: publicBucket,
  cookieOpions
};

// console.log('defaultState :::: ', defaultState);

export default function app(state = defaultState, action) {
  switch (action.type) {
    case "SET_IP":
      return {
        ...state,
        ip: action.value
      };
    case "COORDINATES":
      return {
        ...state,
        coords: action.value
      };
    default:
      return state;
  }
}
